import { ref, Ref } from 'vue';
import { useRoute } from 'vue-router';
import { COMPANY_ROUTES, CAMPAIGN_ROUTES } from '@/router';
import { useStore } from './store';
import { storeToRefs } from 'pinia';

import {
  CmSalesImpactProductsDetails,
  CmSalesImpactReportsDetails,
  CmSalesImpactGraphData
} from '@/api/openapi';

export interface SelectReport extends CmSalesImpactReportsDetails {
  reportId: number;
  purchaseIncreaseAmount: number;
  grp: number;
}

interface EditReport {
  breadcrumbs: Ref<{
    parents: Array<{ name: string; label: string }>;
    current: { label: string };
  }>;
  pageTitle: Ref<string>;
  companyId: Ref<number>;
  reportId: Ref<number>;
  graph: Ref<CmSalesImpactGraphData>;
  report: Ref<CmSalesImpactReportsDetails>;
}

const BREADCRUMBS = {
  parents: [
    { name: COMPANY_ROUTES.top, label: 'ホーム' },
    { name: '', label: 'CMセールスインパクト' },
    {
      name: CAMPAIGN_ROUTES.cmsi,
      label: '売上効果分析',
      query: {
        mode: 'static'
      }
    },
    {
      name: CAMPAIGN_ROUTES.cmsiProduct,
      label: '',
      param: { productId: 0 }
    },
    {
      name: CAMPAIGN_ROUTES.cmsiReport,
      label: '',
      param: { reportId: 0 }
    }
  ],
  current: { label: 'レポートの編集' }
};

export const useEditReport = ({
  product
}: {
  product: CmSalesImpactProductsDetails;
}): EditReport => {
  const route = useRoute();
  const { params } = route;
  const productId = ref(Number(params.productId));
  const companyId = ref(Number(params.companyId));
  const reportId = ref(Number(params.reportId));
  const breadcrumbs = ref(BREADCRUMBS);
  const pageTitle = ref('');
  const graph = ref({} as CmSalesImpactGraphData);

  const store = useStore();
  const { fetchStoreGraphs } = store;
  const { report, ...storeRef } = storeToRefs(store);

  (async () => {
    // タイトルの書き換え
    if (product.productName) {
      const name = product.productName;
      // パンくずの書き換え
      breadcrumbs.value.parents.slice(-2)[0].label = name;
      (breadcrumbs.value.parents.slice(-2)[0].param as {
        productId: number;
      }).productId = productId.value;
    }
    // タイトルの書き換え
    if (report.value.title) {
      const title = report.value.title;
      pageTitle.value = `${title} レポートの編集`;

      // パンくずの書き換え
      breadcrumbs.value.parents.slice(-1)[0].label = title;
      (breadcrumbs.value.parents.slice(-1)[0].param as {
        reportId: number;
      }).reportId = reportId.value;
    }

    //
    graph.value = await fetchStoreGraphs({
      reportId: reportId.value,
      productId: product.productId,
      companyId: companyId.value,
      reachWeeks: product.reachWeeks,
      reachFrequency: product.reachFrequency,
      areaCode: product.areaCode
    });
  })();

  return {
    breadcrumbs,
    companyId,
    reportId,
    pageTitle,
    graph,
    report,
    ...storeRef
  };
};
